@import './shared';

@font-face {
   font-family: 'Gilroy';
   src:  url('./assets/fonts/Gilroy-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/372B4E_0_0.woff2') format('woff2'),
         url('./assets/fonts/372B4E_0_0.woff') format('woff'),
         url('./assets/fonts/372B4E_0_0.eot') format('eot');
}

@font-face {
   font-family: 'GilroySemi';
   src:  url('./assets/fonts/Gilroy-SemiBold.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/372B4E_1_0.woff2') format('woff2'),
         url('./assets/fonts/372B4E_1_0.woff') format('woff'),
         url('./assets/fonts/372B4E_1_0.eot') format('eot');
}

@font-face {
   font-family: 'GilroyBold';
   src:  url('./assets/fonts/Gilroy-ExtraBold.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/372B4E_2_0.woff2') format('woff2'),
         url('./assets/fonts/372B4E_2_0.woff') format('woff'),
         url('./assets/fonts/372B4E_2_0.eot') format('eot');
}

body {
  margin: 0;
  font-family: 'Gilroy' , 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   background-color: $color-dark-blue;
   color: $color-white;
   box-sizing: border-box;
   display: block;
}

strong {
   color: $color-blue;
   font-family: 'GilroyBold';
}

p {
   line-height: 1.4rem;
}

h1 {
   font-family: 'GilroyBold';
   font-size: 2.8rem;
   margin-top: 0;
   margin-bottom: 0.7rem;
}

h2 {
   font-size: 2.2rem;
   font-family: 'GilroyBold';
   margin-top: 0;
   margin-bottom: 1.2rem;
   color: $color-blue;
}

h3 {
   font-size: 1.8rem;
   font-family: 'GilroyBold';
   margin-top: 0;
   margin-bottom: 1rem;
   color: $color-blue;
}

h4 {
   font-size: 1.4rem;
   margin-top: 0;
}

h5 {
   font-size: 1.2rem;
   font-family: 'Gilroy';
   font-weight: 400;
}

h6 {
   font-size: 1.1rem;
   line-height: 1.6rem;
   max-width: 1200px;
   font-family: 'Gilroy';
   margin: auto;
}





.container {
   max-width: 1500px;
   margin: auto;
   padding: 5rem 42px;
   overflow-x: hidden;
}

@media screen and (max-width: 1660px){
   .container {
      max-width: 1400px;
   }
}

@media screen and (max-width: 1520px){
   .container {
      max-width: 1320px;
   }
}

@media screen and (max-width: 1400px){
   .container {
      max-width: 1200px;
   }
}

@media screen and (max-width: 1200px){
   .container {
      padding: 5rem 32px;
   }
}

@media screen and (max-width: 1024px){
   .container {
      padding: 4rem 20px;
   }
   h1 {
      font-size: 2.5rem;
   }
   h2 {
      font-size: 2rem;
   }
}

@media screen and (max-width: 720px){
   .container {
      padding: 4rem 12px;
   }
}





.gray-bg {
   background-color: $color-light-gray;
}

.row {
   display: flex;
}


a {
   text-decoration: none;
   color: $color-white;
   &:hover {
      opacity: 0.8;
   }
}
