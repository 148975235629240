@import '../../shared';

.navbar {
   height: $navbar-height;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   padding-left: 40px;
   padding-right: 40px;
   z-index: 1;

   display: flex;
   align-items: center;

   a {
      text-decoration: none;
      color: white;
      font-weight: 400;
      font-family: GilroySemi;
      font-size: 1.2rem;
      padding-left: 40px;
      display: block;
   }
}

@media screen and (max-width: 1024px){
   .navbar {
      padding-left: 20px;
      padding-right: 20px;
   }
}

@media screen and (max-width: 720px){
   .navbar {
      padding-left: 16px;
      padding-right: 16px;
   }
}
