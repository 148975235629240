@import '../../shared';

footer {
   display: block;
   box-sizing: border-box;
   background-color: $color-dark-blue;
   width: 100%;


   .contact-info-row {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 2rem;

      &>div {
         padding-left: 20px;
         border-left: 0.5px $color-gray solid;

         h5 {
            margin: 0;
            margin-bottom: 8px;
            color: $color-blue;
            font-family: 'GilroyBold';

            &.email {
               color: $color-white;
            }
         };

         img {
            margin-top: 12px;
         }
      }
   }


   .navigation-row {
      display: flex;
      justify-content: space-between;

      img {
         height: 80px;
         margin-bottom: 12px;
      };

      .navigation-items {
         display: flex;
         align-items: center;
         a {
            font-size: 0.8rem;
            color: $color-gray;

            &:hover {
               cursor: pointer;
            }
         };
         .divider {
            background-color: $color-gray;
            height: 12px;
            width: 1px;
            margin-left: 16px;
            margin-right: 16px;
         }
      }
   };


   .bottom-row {
      border-top: 0.5px $color-gray solid;
      padding-top: 12px;
      display: flex;
      align-items: flex-end;
      p {
         margin: 0;
         font-family: 'GilroySemi';
         color: $color-gray;
         font-size: 0.8rem;
      };

      .engineered-success {
         display: flex;
         align-items: flex-end;
         img {
            height: 26px;
            padding-bottom: 3px;
         };
         p {
            color: $color-blue;
            margin-right: 6px;
            min-width: 124px;
            //font-family: 'GilroySemi';
         }
      }
   }
}



footer .isMobile {
   text-align: center;

   .contact-info-row {
      display: block;
      padding-bottom: 2rem;

      &>div {
         padding-left: 0;
         border: none;
      }
   }

   .navigation-row {
      margin-bottom: 40px;
      width: 100%;

      .navigation-items {
         margin: auto;
      }
   }
}
