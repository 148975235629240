$color-dark-blue: #000F1A;
$color-blue: #0075BE;
$color-light-gray: #F0F0F0;
$color-gray: #989898;
$color-text: #333;
$color-white: #fff;

$navbar-height: 100px;

$padding-mobile: 16px;
