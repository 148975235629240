@import '../../shared';

.page-home {

   &__header {
      height: 600px;
      background-color: $color-dark-blue;
      color: white;
      display: flex;

      h1, h2 {
         padding-right: 40px;
      }

      &__image {
         background-image: url('../../assets/smartSensors.jpg');
         background-size: cover;
         background-position: center;
         position: relative;
         width: 50%;
         opacity: 1;

         &>div {
            position: absolute;
            top: 0;
            right: -1px;
            bottom: 0;
            width: 200px;
         }
      }

      &__content {
         width: 50%;
         display: flex;
         align-items: center;
      }
   }

   .mobile-header-image {
      background-image: url('../../assets/smartSensors.jpg');
      background-size: cover;
      background-position: center;
   }

   &__section-intro {
      text-align: center;
      background-image: url('../../assets/mesh-cropped.jpg');
      min-height: 560px;
      background-position-x: center;
      background-position-y: center;
   }


   &__section-andritz {
      background-color: $color-blue;
      color: $color-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      img {
         height: 44px;
      }

      h6 {
         margin-top: 1.2rem;
      }
   }

   &__section-demo {
      position: relative;
      height: 100vh;
      min-height: 400px;
      max-height: 780px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $color-white;

      h2 {
         color: $color-white;
      }

      &__background-image {
         background-image: url('../../assets/smartService.jpg');
         background-position: center;
         background-size: cover;
         opacity: 0.8;
         top: 0;
         left: 0;
         bottom: 0;
         right: 0;
         position: absolute;
      }

      .container {
         z-index: 1;
      }

      h6 {
         max-width: 680px;
      }

      a {
         background-color: $color-blue;
         display: block;
         width: 260px;
         height: 40px;
         display: flex;
         align-items: center;
         justify-content: center;
         margin: auto;
         margin-top: 1.5rem;
         font-size: 1.1rem;

         p {
            padding: 6px;
            font-family: 'GilroyBold';
         }
      }
   }
}


@media screen and (max-width: 1024px){
   .page-home__header {
      h1, h2 {
         padding-right: 0;
      }
   }

   .page-home__section-intro {
      background-position-y: top;
   }
}

@media screen and (max-width: 420px){
   .page-home__section-intro {
      background-position-x: right;
      background-position-y: center;
   }
}
